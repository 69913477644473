import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import React from 'react'
import { ReactElement } from 'react'
import styled from 'styled-components'

type Props = {
  image: IGatsbyImageData
  imageFirst?: boolean
  heading?: string
  columns: {
    rowsHeight?: string
    rows: {
      heading?: string
      htmlContent?: ReactElement
    }[]
  }[]
}

export default (props: Props) => {
  const { image, heading, columns, imageFirst } = props
  return (
    <ColumnsBlock numberOfColumns={columns.length} reverseOrder={imageFirst}>
      {heading && (
        <ColumnsHeading numberOfColumns={columns.length}>
          {heading}
        </ColumnsHeading>
      )}
      {columns.map((column, ic) => {
        return (
          <TextContent
            columnStart={ic + 1}
            numberOfRows={column.rows.length}
            rowsHeight={column.rowsHeight}
            key={ic}
          >
            {column.rows.map((row, ir) => {
              return (
                <div key={ir}>
                  {row.heading && <h3>{row.heading}</h3>}
                  {row.htmlContent}
                </div>
              )
            })}
          </TextContent>
        )
      })}
      <ImageContent>
        <GatsbyImage image={image} alt="" />
      </ImageContent>
    </ColumnsBlock>
  )
}

const ColumnsBlock = styled.div<{
  numberOfColumns: number
  reverseOrder?: boolean
}>`
  direction: ${props => (props.reverseOrder ? 'rtl' : 'ltr')};
  & * {
    direction: ltr;
  }
  --gap: calc(var(--content-container-gap) * 0.5);
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr;
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    grid-template-columns: ${props => `repeat(${props.numberOfColumns}, 1fr)`};
    grid-template-rows: 80px 400px;
  }
`
const ColumnsHeading = styled.h2<{
  numberOfColumns: number
}>`
  grid-row: 1 / span 1;
  margin-block-start: var(--gap);
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    grid-column: 1 / span ${props => props.numberOfColumns};
    grid-row: 1 / span 1;
  }
`
const TextContent = styled.div<{
  columnStart: number
  numberOfRows: number
  rowsHeight?: string
}>`
  display: grid;
  gap: var(--gap);
  h3 {
    margin-block-end: 8px;
  }
  button {
    font-size: 0.9rem;
    /* font-size: clamp(0.8rem, 1vw, 1.1rem); */
  }
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    grid-template-rows: ${props =>
      `repeat(${props.numberOfRows}, ${props.rowsHeight ?? '1fr'})`};
    grid-row: 2 / span 1;
    grid-column: ${props => props.columnStart} / span 1;
  }
`

const ImageContent = styled.div`
  grid-row: 2 / span 1;
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    grid-column: 3 / span 4;
    grid-row: 1 / span 3;
  }
`
