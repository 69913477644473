import { AnchorButton } from '@mehilainen/design-system'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { ImageContext, ProductImageObj, updateImageWithContext } from './Home'
import ModularContent, { ModularContentObject } from 'components/ModularContent'
import React, { useEffect, useMemo } from 'react'

import ColumnsImageBlock from 'components/ColumnsImageBlock/ColumnsImageBlock'
import ContentContainer from 'components/ContentContainer'
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks'
import ProductListing from 'components/ProductListing'
import Seo from 'components/Seo'
import { giftCardSku } from './ProductGiftCard'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { viewItemListGA } from 'gtag.js/utils'
import { SecondaryHero } from 'components/SecondaryHero'

interface Props {
  data: {
    magento: {
      categoryList: Category[]
    }
    allFile: {
      nodes: ProductImageObj[]
    }
    heroImage: {
      nodes: FileNode[]
    }
    socialShareImage: {
      nodes: { publicURL: string }[]
    }
    checkupsBenefitsImage: {
      nodes: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }[]
    }
    customerServiceImage: {
      nodes: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }[]
    }
  }
  pageContext: {
    images: ImageContext[]
  }
}

interface Category {
  name: string
  meta_description: string
  meta_title: string
  image: string
  description: string
  content_constructor_content: string
  products: {
    items: Product[]
  }
}

export default ({ data, pageContext }: Props) => {
  const category = data.magento.categoryList[0] // We have only one category
  const products = useMemo(
    () => category.products.items || [],
    [category.products.items]
  )
  const images = updateImageWithContext(data.allFile.nodes, pageContext.images)

  const modular_content = category.content_constructor_content
    ? (JSON.parse(
        category.content_constructor_content
      ) as ModularContentObject[])
    : undefined

  // THe description is in the fist paragraph
  const description = modular_content?.find(
    content => content.type === 'paragraph'
  )

  // const restOfContent = modular_content?.filter(c => c.id !== description?.id)

  useEffect(() => {
    viewItemListGA(products, giftCardSku)
  }, [products])

  return (
    <>
      <Seo
        title={category.name}
        metatitle={category.meta_title}
        description={category.meta_description}
        image={data.socialShareImage.nodes[0].publicURL}
      />

      <SecondaryHero
        title={
          modular_content?.find(content => content.type === 'paragraph')?.data
            .title || 'Terveydentilaa selventävät tarkastukset'
        }
      />

      <ContentContainer>
        <Description>
          {description && <ModularContent content={description} />}
        </Description>
        <ProductListing products={products} images={images} />
        <ColumnsImageBlock
          image={
            data.checkupsBenefitsImage.nodes[0].childImageSharp.gatsbyImageData
          }
          heading="Terveystarkastusten hyödyt"
          columns={[
            {
              rows: [
                {
                  heading: 'Mielenrauha',
                  htmlContent: (
                    <p>
                      Terveystarkastusten avulla saat helposti ja kätevästi
                      selville, mitä terveydelle ja hyvinvoinnille kuuluu.
                    </p>
                  ),
                },
                {
                  heading: 'Tukea ja apua',
                  htmlContent: (
                    <p>
                      Saat asiantuntijoiltamme tukea testituloksiin ja
                      jatkotoimenpiteisiin liittyen.
                    </p>
                  ),
                },
              ],
            },
            {
              rows: [
                {
                  heading: 'Helposti ja nopeasti',
                  htmlContent: (
                    <p>
                      Ostaminen verkkokaupasta on helppoa ja meillä pääset
                      tarkastukseen nopeasti
                    </p>
                  ),
                },
                {
                  heading: 'Kampanjat ja edut',
                  htmlContent: (
                    <p>
                      Tarjoamme asiakkaillemme etuja tarkastuksiin liittyen.{' '}
                      <a
                        className="apply-link-style"
                        href="https://www.mehilainen.fi/mobiiliedut"
                      >
                        Tutustu Mobiilietuihin.
                      </a>
                    </p>
                  ),
                },
              ],
            },
          ]}
        />
        <ColumnsImageBlock
          image={
            data.customerServiceImage.nodes[0].childImageSharp.gatsbyImageData
          }
          imageFirst={true}
          heading="Kaipaatko lisätietoa?"
          columns={[
            {
              rowsHeight: 'max-content',
              rows: [
                {
                  htmlContent: <p>Ota yhteyttä Mehiläisen asiakaspalveluun.</p>,
                },
                {
                  htmlContent: (
                    <p>
                      <a href="tel:010 414 00">010 414 00</a> (0,0835 €/min)
                      Avoinna joka päivä vuorokauden ympäri.
                    </p>
                  ),
                },
                {
                  htmlContent: (
                    <AnchorButton
                      secondary
                      href="https://www.mehilainen.fi/yhteystiedot"
                      className="apply-link-style"
                      target="_blank"
                      rel="noopener"
                      showIcon
                    >
                      Mehiläisen asiakaspalvelun tiedot
                    </AnchorButton>
                  ),
                },
              ],
            },
          ]}
        />
      </ContentContainer>
    </>
  )
}

const Description = styled.div`
  max-width: 800px;
  margin: auto;
  text-align: center;
`

export const query = graphql`
  query Category {
    magento {
      categoryList(filters: { parent_id: { in: ["2"] } }) {
        name
        description
        meta_description
        meta_title
        image
        content_constructor_content
        products {
          items {
            brand
            name
            meta_description
            sku
            small_image {
              url
            }
            url_key
            price_range {
              minimum_price {
                regular_price {
                  value
                }
              }
            }
            categories {
              name
              url_key
            }
            gift_card_amounts
          }
        }
      }
    }
    allFile(filter: { extension: { ne: "svg" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    heroImage: allFile(filter: { name: { eq: "tarkastukset" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    socialShareImage: allFile(filter: { name: { eq: "socialshareimage" } }) {
      nodes {
        publicURL
      }
    }
    checkupsBenefitsImage: allFile(
      filter: { name: { eq: "checkups-benefits" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    customerServiceImage: allFile(filter: { name: { eq: "support-service" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
