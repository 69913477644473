import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { selectItemGA } from 'gtag.js/utils'
import styled from 'styled-components'

interface Props {
  product: Product
  image: IGatsbyImageData | undefined
  index?: number
}

export default ({ product, image, index }: Props) => {
  const productPath = product.categories
    ? `/${product.categories[0]?.url_key}/${product.url_key}/`
    : `/${product.url_key}/`

  return (
    <Link
      to={productPath}
      onClick={() => selectItemGA({ item: product, index })}
    >
      <ImageContainer>
        {image && (
          <GatsbyImage
            image={image}
            alt=""
            style={{
              borderRadius: '8px',
              height: '452px',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        )}
        <ImageTextContainer>
          {product.categories && (
            <Category>{product.categories[0]?.name}</Category>
          )}
          <Title>{product.name}</Title>
          <Description>{product.meta_description}</Description>
        </ImageTextContainer>
      </ImageContainer>
    </Link>
  )
}

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: auto;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #00250c 100%);
    z-index: 1;
  }

  border-radius: 8px;
  overflow: hidden;
`

const ImageTextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  text-align: left;
  box-sizing: border-box;
  z-index: 2;
`

const Category = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  color: white;
  padding-bottom: 8px;
`

const Title = styled.div`
  font-size: 1.5em;
  color: white;
  text-align: left;
  padding-bottom: 16px;
  text-decoration: underline;
`

const Description = styled.p`
  font-size: 1em;
  color: white;
  padding: 0 !important;
`
