import ImageCard from './ImageCard'
import { ProductImageObj } from 'templates/Home'
import React from 'react'
import styled from 'styled-components'

interface Props {
  products: Product[]
  images: ProductImageObj[]
}

export default ({ products, images }: Props) => {
  if (!products) return null
  return (
    <CardsContainer>
      {products.map((product, index) => (
        <ImageCard
          product={product}
          image={
            images.find(
              img => img.productSku === product.sku && img.smallImage === true
            )?.childImageSharp.gatsbyImageData
          }
          index={index}
          key={index}
        />
      ))}
    </CardsContainer>
  )
}

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: auto;
  padding: 0 80px 0 80px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px 0 16px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 16px 0 16px;
  }
`
