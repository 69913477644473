import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Link } from 'gatsby'
import React from 'react'
import { giftCardSku } from 'templates/ProductGiftCard'
import { selectItemGA } from 'gtag.js/utils'
import styled from 'styled-components'
import { Button } from '@mehilainen/design-system'

interface Props {
  product: Product
  image: IGatsbyImageData | undefined
  index?: number
}

export default function ProductCard({ product, image, index }: Props) {
  const prices = JSON.parse(product.gift_card_amounts ?? '[]')
  const price =
    prices.length && product.sku !== giftCardSku ? prices[0]?.amount : undefined

  const productPath =
    product.sku === 'lahjakortti' || !product.categories
      ? `/${product.url_key}/`
      : `/${product.categories[0].url_key}/${product.url_key}/`

  const handleButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    selectItemGA({
      item: product,
      price,
      index,
      giftCardSku,
    })
    window.location.href = productPath
  }

  return (
    <Link
      to={productPath}
      onClick={() => selectItemGA({ item: product, price, index, giftCardSku })}
    >
      <Container>
        {image && <GatsbyImage image={image} alt={product.name} />}
        <Content>
          {product.categories && (
            <Category>{product.categories[0].name}</Category>
          )}
          <Name>{product.name}</Name>
          <Description>{product.meta_description}</Description>
          {product.sku === giftCardSku ? (
            <Price>20-2000 €</Price>
          ) : (
            <Price>{price} €</Price>
          )}
        </Content>
        <ButtonContainer>
          <StyledButton as="a" href={productPath} onClick={handleButtonClick}>
            Osta tästä
          </StyledButton>
        </ButtonContainer>
      </Container>
    </Link>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 320px;
  max-width: 94vw;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 0px hsla(0, 0%, 0%, 0.07);
  line-height: 1.2;
  text-align: left;
  transform: scale(1);
  transition: transform 0.2s ease;
  background-color: white;

  img {
    height: 200px !important; // To override gatsby image height in all css loading orders
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease;
  }
`
const Name = styled.h3`
  font-size: 15px;
  color: #004e23;
  padding-bottom: 10px;
  text-decoration: none;
  transition: text-decoration 0.2s ease;
  text-decoration: underline;
`

const Content = styled.div`
  flex: 1;
  flex-direction: column;
  gap: 10px;
  margin: 15px;
  line-height: 1.7 !important;
`

const Price = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #004e23;
`

const Description = styled.p`
  font-size: 13px;
  padding-bottom: 10px;
  color: black !important;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
`

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-color: ${props => props.theme.primaryGreen};
  border-radius: 25px;
  text-transform: none;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  padding: 15px 24px;

  :hover {
    background: ${props => props.theme.primaryGreen};
    color: white;
  }

  @media (min-width: 1024px) {
    align-self: flex-start;
  }
`

const Category = styled.div`
  color: ${props => props.theme.primaryGreen};
  text-transform: uppercase;
  font-size: 11px;
`
