import ProductCard from './ProductCard'
import { ProductImageObj } from 'templates/Home'
import React from 'react'
import styled from 'styled-components'
import { H2 } from '@mehilainen/design-system'

interface Props {
  products: Product[]
  images: ProductImageObj[]
}

export default ({ products, images }: Props) => {
  if (!products) return null
  return (
    <Container>
      <TitleContainer>
        <Title>Tutustu tuotteisiin</Title>
      </TitleContainer>
      <CardsContainer>
        {products.map((product, index) => (
          <ProductCard
            product={product}
            image={
              images.find(
                img => img.productSku === product.sku && img.smallImage === true
              )?.childImageSharp.gatsbyImageData
            }
            index={index}
            key={index}
          />
        ))}
      </CardsContainer>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  margin: 40px 0 40px 0;
`

const Title = styled(H2)`
  color: ${props => props.theme.colors.primary[700]};
`

const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-wrap: wrap;
`
