import { H1 } from '@mehilainen/design-system'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'

import ContentContainer from 'components/ContentContainer'
import HomeHero from 'components/HomeHero'
import Seo from 'components/Seo'
import { giftCardSku } from './ProductGiftCard'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { viewItemListGA } from 'gtag.js/utils'
import ImageAndText from 'components/ImageAndText'
import ImageCardList from 'components/ImageCardList'

interface Props {
  data: {
    magento: {
      cmsPage: PageData
      products: {
        items: Product[]
      }
    }
    allFile: {
      nodes: ImageProps[]
    }
    socialShareImage: {
      nodes: { publicURL: string }[]
    }
  }
  pageContext: {
    images: ImageContext[]
  }
}

export interface ImageContext {
  id: string
  productSku: string
  smallImage: boolean | null
}

export interface ProductImageObj extends ImageProps {
  productSku?: string
  smallImage?: boolean | null
}

export interface ImageProps {
  id: string
  url: string
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData
  }
  publicURL?: string
}

interface PageData {
  content: string
  title: string
  meta_description: string
  meta_title: string
}

export default ({ data, pageContext }: Props) => {
  const page = data.magento.cmsPage
  const products = data.magento.products.items
  const images = updateImageWithContext(data.allFile.nodes, pageContext.images)
  const healthCheckProducts = products.filter(
    product =>
      product.categories && product.categories[0].name === 'Tarkastukset'
  )

  useEffect(() => {
    viewItemListGA(products, giftCardSku)
  }, [products])

  return (
    <>
      <Seo
        title={page.title}
        metatitle={page.meta_title}
        description={page.meta_description}
        image={data.socialShareImage.nodes[0].publicURL}
      />
      <HomeHero
        title="Kerro että välität, anna lahjaksi Mehiläisen lahjakortti"
        text="Lahjakortti käy kaikissa Mehiläisen toimipisteissä Suomessa. Lahjan saaja saa itse valita laajasta terveys- ja hyvinvointipalveluiden valikoimastamme. Summan valitset itse."
      />

      <ContentContainer>
        <TitleContainer>
          <H1>Mehiläisen verkkokauppa</H1>
          <p>
            Mehiläisen verkkokaupasta löydät terveyteen liittyviä tuotteita ja
            palveluita, jotka edistävät päivittäistä hyvinvointiasi.
          </p>
        </TitleContainer>
        {/* TODO: Add product listing back when we have more products */}
        {/* <ProductListing products={healthCheckProducts} images={images} /> */}
      </ContentContainer>
      <ContentWrapper>
        <ImageCardList products={healthCheckProducts} images={images} />
        <ImageAndText />
      </ContentWrapper>
    </>
  )
}

// Adds productSku and smallImage to image nodes
export function updateImageWithContext(
  graohQlImages: ImageProps[],
  contextImages: ImageContext[]
): ProductImageObj[] {
  return graohQlImages.map(img => {
    const imgData = contextImages.find(imgData => {
      return img.id === imgData.id
    })
    return {
      productSku: imgData?.productSku,
      smallImage: imgData?.smallImage,
      ...img,
    }
  })
}

const TitleContainer = styled.div`
  text-align: center;
  display: grid;
  gap: calc(var(--content-container-gap) * 0.5);
  > p {
    text-align: center;
    max-width: 900px;
    font-size: 18px;
    margin: auto;
    line-height: 1.8;
  }
  > H1 {
    color: #004e23;
    padding-top: 40px;
    font-size: 32px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  justify-content: center;
  padding: 80px 0 0 0;

  @media (min-width: ${props => props.theme.tabletBreakpoint}) {
    text-align: center;
    p {
      padding: 0 16px;
    }
  }
`

export const query = graphql`
  query Home {
    magento {
      cmsPage(identifier: "home") {
        content
        title
        meta_description
        meta_title
      }
      products(search: "", sort: { name: ASC }) {
        items {
          sku
          url_key
          name
          brand
          small_image {
            url
          }
          meta_description
          short_description {
            html
          }
          price_range {
            minimum_price {
              regular_price {
                value
              }
            }
          }
          categories {
            name
            url_key
          }
          gift_card_amounts
        }
      }
    }
    allFile(filter: { extension: { ne: "svg" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    heroImage: allFile(filter: { name: { eq: "home_hero" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    socialShareImage: allFile(filter: { name: { eq: "socialshareimage" } }) {
      nodes {
        publicURL
      }
    }
  }
`
