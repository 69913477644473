import React from 'react'
import styled from 'styled-components'
import Image from './img/hero/lahjis1.png'
import { Button } from '@mehilainen/design-system/lib/components/Button'
import { Link } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

interface Props {
  src?: string
  title?: string
  text?: string
  image?: IGatsbyImageData
}

const HomeHero: React.FC<Props> = ({ src, title, text }) => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroTextContainer>
          {title && <HeroTitle>{title}</HeroTitle>}
          {text && <HeroText>{text}</HeroText>}
          <StyledButton as={Link} to="/lahjakortti">
            Osta tästä
          </StyledButton>
        </HeroTextContainer>
        <HeroImage src={Image} alt="" />
      </HeroContent>
    </HeroContainer>
  )
}

export default HomeHero

const HeroContainer = styled.div`
  background-color: ${props => props.theme.primaryGreen};
  width: 100%;

  @media (min-width: 1023px) {
    flex-direction: row;
  }
`

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1200px;
  margin: auto;
  padding: 20px 40px;
  min-height: 45vh;

  @media (min-width: 1023px) {
    flex-direction: row;
    min-height: 586px;
  }
`

const HeroImage = styled.img`
  width: 100%;
  max-height: 55vh;
  object-fit: contain;

  @media (min-width: 1025px) {
    min-width: 545px;
  }

  @media (max-width: 1024px) {
    max-width: 450px;
    height: 450px;
    min-width: unset;
  }
`

const HeroTextContainer = styled.div`
  max-width: 560px;

  @media (min-width: 1024px) {
    margin-right: 20px;
    margin-top: 0;
    text-align: left;
  }

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    text-align: center;
  }
`

const HeroTitle = styled.h2`
  color: white;
  font-size: 2.5rem;
  line-height: 1.4 !important;
  margin-bottom: 0;
`

const HeroText = styled.p`
  color: white;
  font-size: 1.13rem;
  line-height: 1.7 !important;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: ${props => props.theme.mobileBreakpoint}) {
    font-size: 0.9375rem;
  }
`

const StyledButton = styled(Button)`
  max-width: 200px;
  align-self: center;
  color: ${props => props.theme.primaryGreen};
  background-color: white;
  border-radius: 25px;
  text-transform: none;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  padding: 15px 24px;

  :hover {
    background: ${props => props.theme.colors.background.secondary};
  }

  @media (min-width: 1024px) {
    align-self: flex-start;
  }
`
