import ImageHero from './img/hero/verkkokauppa-hero-tarkastukset.png'
import { H1 } from '@mehilainen/design-system'
import React from 'react'
import styled from 'styled-components'

interface Props {
  src?: string
  title?: string
  text?: string
}

export const SecondaryHero: React.FC<Props> = ({ src, title, text }) => {
  return (
    <>
      <HeroContainer>
        <HeroImage src={ImageHero} alt="" />
        <Content>
          {title && <H1>{title}</H1>}
          {text && <p>{text}</p>}
        </Content>
      </HeroContainer>
    </>
  )
}

const HeroImage = styled.img`
  object-fit: cover;
  height: 350px;
  max-height: 35vh !important;
  width: 100%;
  img {
    max-height: 35vh !important;
  }
`

const HeroContainer = styled.div`
  margi: auto;
  margin-block-end: 32px;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
`

const Content = styled.div`
  text-align: center;
  padding-inline: 24px;

  p {
    font-size: 18px;
  }

  h1 {
    margin-block: 0;
  }

  @media (max-width: ${props => props.theme.mobileBreakpoint}) {
    p {
      font-size: 15px;
    }
  }
`
