import React from 'react'
import styled from 'styled-components'
import GiftCardImage from './img/giftcard_general.jpg'
import { Button } from '@mehilainen/design-system'
import { Link } from 'gatsby'

const ImageAndText = () => {
  return (
    <Container>
      <Content>
        <Image src={GiftCardImage} alt="" />
        <TextContainer>
          <Title>Ilahduta ystävää Mehiläisen lahjakortilla</Title>
          <Description>
            Mehiläisen lahjakortilla panostat läheistesi hyvinvointiin ja
            osoitat, että heidän hyvinvointinsa on sinulle tärkeää. Lahjakortti
            on muodoltaan digitaalinen ja se toimitetaan lahjakortin
            vastaanottajalle sähköpostilla. Voit myös ostaa lahjakortin ensin
            itsellesi ja tulostaa tai lähettää sen vastaanottajalle.
          </Description>
          <ButtonContainer>
            <StyledButton as={Link} to="/lahjakortti">
              Osta tästä
            </StyledButton>
          </ButtonContainer>
        </TextContainer>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px;
  background-color: ${props => props.theme.colors.primary[100]};
  margin: auto;

  @media (max-width: 1100px) {
    flex-direction: row;
    padding: 80px;
  }

  @media (max-width: 760px) {
    padding: 80px 16px;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`

const Image = styled.img`
  max-width: 100%;
  border-radius: 8px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: left;
  padding: 40px 0 0 40px;

  @media (max-width: 1100px) {
    padding: 0px;
  }
`

const Title = styled.div`
  font-size: 24px;
  padding: 0px 0px 0px 16px;
  color: ${props => props.theme.colors.primary[700]};
  text-align: left;
  line-height: 1.2;

  @media (max-width: 1100px) {
    padding-top: 40px;
  }
`

const Description = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  padding-left: 16px;
`

const ButtonContainer = styled.div`
  padding: 0px 0px 0px 16px;
`

const StyledButton = styled(Button)`
  max-width: 200px;
  align-self: center;
  color: white;
  background-color: ${props => props.theme.colors.primary[500]};
  border-radius: 25px;
  text-transform: none;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  padding: 15px 24px;

  :hover {
    background: ${props => props.theme.colors.primary[700]};
    color: white;
  }
`

export default ImageAndText
